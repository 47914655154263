<template>
  <div class="invisible w-full h-full fixed pointer-events-none"></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentSong"]),
  },
  watch: {
    currentSong(newSong, oldSong) {
      if (newSong && newSong !== oldSong) {
        this.playMusic(newSong);
      } else {
        this.pauseMusic();
      }
    },
  },
  data() {
    return {
      audio: new Audio(),
    };
  },
  methods: {
    playMusic(song) {
      try {
        if (this.audio.paused) {
          this.pauseMusic();
          this.audio.src = song.path;
          this.audio.volume = 0.1;
          this.audio
            .play()
            .then(() => {
              this.audio.muted = false;
            })
            .catch(() => { });
        }
      } catch (e) {
        e.message;
      }
    },
    pauseMusic() {
      this.audio.pause();
    },
  },
};
</script>
