<template>
  <div class="fixed z-[300] w-screen h-screen" @click="setterSongModal">
    <div class="w-full h-full relative flex items-center justify-center">
      <div ref="container" @click.prevent.stop
        class="p-4 border-neutral-300 bg-neutral-100 dark:bg-primary-800 dark:border-primary-500 border z-[1] rounded-lg animate-zoom-in"
        :style="`width: ${resizedScreen}px; height: ${resizedScreen}px;`">
        <div class="relative z-[1] w-full h-full flex flex-col justify-end items-end" v-if="tempSongInfo">
          <div class="vinyl-jacket transition-all duration-500" :class="showVinyl ? 'right-[45%]' : 'right-[30%]'"
            :style="{ backgroundImage: `url(${tempSongInfo.image})` }"></div>
          <div class="vinyl-wrapper group-hover:rotate-12 transition-all duration-500" :class="[
            showVinyl ? 'left-[45%]' : 'left-[30%]',
            { 'animate-spin-slow': spinVinyl },
          ]">
            <div class="vinyl"></div>
          </div>
          <div class="w-full px-[10px]">
            <div class="flex flex-col">
              <h1 @click="openLink(tempSongInfo.songLink)"
                class="text-black/80 dark:text-neutral-200 font-semibold max-w-full overflow-hidden whitespace-nowrap overflow-ellipsis hover:underline w-max"
                :class="resizedScreen < 300 ? '' : 'text-lg'">
                {{ tempSongInfo.songName }}
              </h1>
              <p class="text-neutral-500 dark:text-neutral-400 max-w-full flex overflow-hidden whitespace-nowrap overflow-ellipsis"
                :class="resizedScreen < 300 ? 'text-xs' : ''">
                <span v-for="(artist, id) in tempSongInfo.artists" :key="id" class="w-max flex">
                  <span v-if="id !== 0">,</span>
                  <a @click="openLink(artist.link)" class="hover:underline" :class="{ 'ml-1': id !== 0 }">
                    {{ artist.name }}</a>
                </span>
              </p>
              <div class="md:h-10 h-8 pt-3">
                <div class="relative w-full bg-neutral-400/40 dark:bg-neutral-400/50 rounded-full overflow-hidden">
                  <div class="h-[3px] bg-black dark:bg-white absolute top-0 left-0" :style="{ width: `${percent}%` }">
                  </div>
                  <div class="w-full mt-[3px]"></div>
                </div>
                <div class="flex items-center justify-between text-neutral-500 dark:text-neutral-400 text-xs mt-[3px]">
                  <span id="currentTime">{{ formattedCurrentTime }}</span>
                  <span id="totalTime">{{ formattedTotalTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full h-full flex flex-col justify-center items-center gap-y-4" v-if="songInfo === null">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true"
            class="w-20 h-20 text-neutral-600 dark:text-neutral-400" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.707 2.293a1 1 0 00-1.414 1.414l6.921 6.922c.05.062.105.118.168.167l6.91 6.911a1 1 0 001.415-1.414l-.675-.675a9.001 9.001 0 00-.668-11.982A1 1 0 1014.95 5.05a7.002 7.002 0 01.657 9.143l-1.435-1.435a5.002 5.002 0 00-.636-6.294A1 1 0 0012.12 7.88c.924.923 1.12 2.3.587 3.415l-1.992-1.992a.922.922 0 00-.018-.018l-6.99-6.991zM3.238 8.187a1 1 0 00-1.933-.516c-.8 3-.025 6.336 2.331 8.693a1 1 0 001.414-1.415 6.997 6.997 0 01-1.812-6.762zM7.4 11.5a1 1 0 10-1.73 1c.214.371.48.72.795 1.035a1 1 0 001.414-1.414c-.191-.191-.35-.4-.478-.622z">
            </path>
          </svg>
          <p class="neutral-neutral-800 dark:text-neutral-200">{{ $t("user_disconnected") }}</p>
        </div>
      </div>
      <div class="absolute w-full h-full backdrop-blur-[3px]"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      resizedScreen: "400",
      tempSongInfo: null,
      showVinyl: false,
      spinVinyl: false,
      duration: 0,
      currentTime: 0,
      startTime: 0,
      percent: 0,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    if (this.songInfo === null) return;

    this.resetAndAnimate(this.songInfo);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 340) {
        this.resizedScreen = `${window.innerWidth - 40}`;
      } else {
        this.resizedScreen = "300";
      }
    },
    startProgressAnimation() {
      this.startTime = Date.now();
      window.requestAnimationFrame(this.updateCounter);
    },
    updateCounter() {
      const currentTime = Date.now();
      const startTime = this.songInfo.startTime;
      const elapsedMilliseconds = currentTime - startTime;

      const elapsedSeconds = elapsedMilliseconds / 1000;

      if (elapsedSeconds < this.duration) {
        this.currentTime = this.songInfo.progress / 1000 + elapsedSeconds;
        this.percent = (this.currentTime / this.duration) * 100;

        if (this.percent <= 100) window.requestAnimationFrame(this.updateCounter);
        else {
          this.showVinyl = false;
          this.currentTime = this.duration;
        }
      }
    },
    resetAndAnimate(songInfo) {
      this.showVinyl = false;
      this.spinVinyl = false;

      setTimeout(() => {
        const img = new Image();
        img.src = songInfo.image;

        img.onload = () => {
          this.tempSongInfo = songInfo;
          setTimeout(() => {
            this.showVinyl = true;
            setTimeout(() => {
              this.spinVinyl = true;
            }, 100);
          }, 100);
        };
      }, 100);

      this.duration = this.songInfo.duration / 1000;
      this.startProgressAnimation();
    },
    ...mapMutations(["setCurrentSongModal"]),
    setterSongModal() {
      if (this.$refs.container !== null) {
        this.$refs.container.classList.remove('animate-zoom-in');
        this.$refs.container.classList.add('animate-zoom-out');
      }

      setTimeout(() => {
        if (this.$refs.container !== null) this.$refs.container.classList.add('hidden');
        this.setCurrentSongModal(false);
      }, 400);
    },
    openLink(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    ...mapState(["songInfo"]),
    formattedTotalTime() {
      const minutes = Math.floor(this.songInfo.duration / 60000);
      const seconds = ((this.songInfo.duration % 60000) / 1000).toFixed(0);
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    },
    formattedCurrentTime() {
      const totalSeconds = Math.floor(this.currentTime);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    },
  },
  watch: {
    songInfo: {
      handler(newSongInfo) {
        if (newSongInfo === null) {
          this.tempSongInfo = null;
          return;
        }

        this.resetAndAnimate(newSongInfo);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.vinyl-jacket {
  position: absolute;
  width: 50%;
  height: 50%;
  z-index: 2;
  top: 10%;
  background-size: cover;
  filter: drop-shadow(0px 10px 9px rgba(0, 0, 0, 0.4));
}

.vinyl-wrapper {
  position: absolute;
  top: 10%;
  width: 50%;
  height: 50%;
  filter: drop-shadow(0 0 1px rgba(#000, 0.6));
}

.vinyl {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: #21201e;
  background-image: conic-gradient(from 45deg,
      rgba(255, 255, 255, 0.25),
      transparent 6.25% 43.75%,
      rgba(255, 255, 255, 0.25) 50%,
      transparent 56.25% 93.75%,
      rgba(255, 255, 255, 0.25)),
    radial-gradient(farthest-side, transparent 0 calc(100% - 1px), #342d2b 95.23% 100%),
    radial-gradient(farthest-side, transparent 0 95.23%, #342d2b 95.23% 100%),
    radial-gradient(farthest-side,
      rgba(255, 255, 255, 0.06) 0 40%,
      rgba(0, 0, 0, 0.2) 40% calc(40% + 1px),
      rgba(0, 0, 0, 0.12) calc(40% + 1px) 48%,
      #282826 48% calc(48% + 1px),
      transparent calc(48% + 1px) 100%),
    radial-gradient(farthest-side,
      transparent 63.8%,
      rgba(255, 255, 255, 0.03) 63.8% calc(63.8% + 1px),
      transparent calc(63.8% + 1px) 81.9%,
      rgba(255, 255, 255, 0.03) 81.9% calc(81.9% + 1px),
      transparent 0),
    repeating-radial-gradient(farthest-side,
      rgba(255, 255, 255, 0.005) 0 1px,
      transparent 1px 2px),
    repeating-radial-gradient(farthest-side,
      rgba(255, 255, 255, 0.005) 0 1px,
      transparent 1px 5px),
    repeating-radial-gradient(farthest-side, #161616, #111111 12.5%);
  mask-image: radial-gradient(transparent 2.4%, #fff 2.4%);
  box-shadow: inset 0 0 1px 1px #000, inset 0 0 0 2px rgba(110, 100, 101, 0.6);
}
</style>
