<template>
  <div @mouseover="toggleAnimation(false)" @mouseleave="toggleAnimation(true)">
    <slot></slot>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["setNormalCursor"]),
    toggleAnimation(state) {
      this.setNormalCursor(state);
    },
  },
};
</script>
