import { createRouter, createWebHistory } from 'vue-router';
import Home from './view/Home/Show.vue';

const routes = [
    { path: '/', component: Home },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, left: 0 };
    },
});

export default router;