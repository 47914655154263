<template>
  <SpotifyTrackSync />
  <div class="min-h-screen max-h-max">
    <CustomCursor />
    <DynamicRoute />
    <VerticalGradient />
    <Vynil v-if="currentSongModal" />
    <main class="mx-auto w-full max-w-screen-md px-4 pb-24 pt-20">
      <router-view class="router-view"></router-view>
    </main>
  </div>
</template>

<script>
import { addMetaTag } from "@/utils/seo.js";
import { mapState, mapMutations } from "vuex";
import Vynil from "@/components/Vynil.vue";
import CustomCursor from "./Partials/CustomCursor.vue";
import DynamicRoute from "./Partials/DynamicRoute.vue";
import VerticalGradient from "./Partials/VerticalGradient.vue";
import SpotifyTrackSync from "./Partials/SpotifyTrackSync.vue";

export default {
  beforeCreate() {
    addMetaTag("description", this.$t("description"));
    addMetaTag("og:type", "website", true);
    addMetaTag("og:title", this.$t("title_in"), true);
    addMetaTag("og:description", this.$t("description"), true);
    addMetaTag("og:image", `${window.location.origin}/assets/images/logo_dark.png`, true);
    addMetaTag("og:url", `${window.location.origin}${window.location.pathname}`, true);
    addMetaTag("og:email", "sebdev.rs@gmail.com", true);
    addMetaTag("og:country-name", "Chile", true);
    addMetaTag("og:site_name", window.location.hostname.split(".")[0], true);

    let canonicalLink = document.createElement("link");
    canonicalLink.setAttribute("rel", "canonical");
    canonicalLink.setAttribute(
      "href",
      `${window.location.origin}${window.location.pathname}`
    );
    document.querySelector("head").appendChild(canonicalLink);
  },
  components: {
    Vynil,
    CustomCursor,
    DynamicRoute,
    VerticalGradient,
    SpotifyTrackSync,
  },
  computed: {
    ...mapState(["currentSongModal"]),
    ...mapState(["currentLightDark"]),
    ...mapState(["currentLang"]),
  },
  methods: {
    ...mapMutations(["setCurrentLightDark"]),
    toggleLightDark() {
      this.setCurrentLightDark(this.currentLightDark);
    },
    ...mapMutations(["setCurrentLang"]),
    toggleLang() {
      this.setCurrentLang(this.currentLang);

      this.$i18n.locale = this.currentLang ? "en" : "es";
    },
    changeFavicon(faviconPath) {
      let link =
        document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = faviconPath;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    faviconPath(value) {
      return value ? "/favicon_dark.ico" : "/favicon.ico";
    },
  },
  beforeMount() {
    document.title = this.$i18n.t("title_in");
    this.toggleLightDark();
    this.toggleLang();
  },
  mounted() {
    window.addEventListener("blur", () => {
      document.title = this.$i18n.t("title_out");
    });
    window.addEventListener("focus", () => {
      document.title = this.$i18n.t("title_in");
    });

    this.changeFavicon(this.faviconPath(this.currentLightDark));
  },
  watch: {
    "$i18n.locale": {
      handler() {
        document.title = this.$i18n.t("title_in");
      },
      deep: true,
    },
    currentLightDark(newValue) {
      this.changeFavicon(this.faviconPath(newValue));
    },
  },
};
</script>

<style>
* {
  -webkit-tap-highlight-color: transparent;
}

img,
button,
a {
  -webkit-user-drag: none;
  cursor: none;
}

::selection {
  background-color: rgb(211, 211, 211, 0.5);
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: rgb(250, 250, 250);
  cursor: none;
}

.dark ::selection {
  background-color: rgba(78, 77, 77, 0.5);
}

.dark body {
  background-color: rgb(18, 17, 19);
}

.dark ::-webkit-scrollbar-thumb {
  background: rgb(28 26 30);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(226, 226, 226);
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.paragraph {
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: -0.1px;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
