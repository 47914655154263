<template>
  <h2
    class="md:hidden visible text-left md:text-center font-bold text-xl text-black dark:text-white md:mb-2 py-4 md:py-4 top-0 sticky bg-neutral-50 dark:bg-primary-900 z-[50]">
    {{ $t("about_me_section_title") }}
  </h2>
  <div class="relative">
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[200px] h-[200px] pointer-events-none -z-20 opacity-10">
      <span className="relative inline-block overflow-hidden rounded-full p-[1px] animate-smooth-bounce">
        <span
          className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
        <div
          className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950/90 dark:bg-white/90 duration-700 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl">
          <img :src="getImage" alt="Logo" class="rounded-full" :class="getDropShadow" width="200px" height="200px" />
        </div>
      </span>
    </div>
    <div :class="getDropShadowText"
      class="text-black/60 dark:text-white/60 space-y-3 paragraph text-sm leading-normal z-50">
      <p>
        {{ $t("description") }}
      </p>
      <p class="whitespace-pre-wrap">
        {{ $t("my_beginnings_paragraph_1") }}
      </p>
      <p>
        {{ $t("my_beginnings_paragraph_2") }}
      </p>
      <p>
        {{ $t("my_beginnings_paragraph_3") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getImage() {
      return this.$store.state.currentLightDark
        ? require("@/assets/images/logo_dark.webp")
        : require("@/assets/images/logo_light.webp");
    },
    getDropShadow() {
      return !this.$store.state.currentLightDark ? "drop-shadow-xs" : "drop-light-xs";
    },
    getDropShadowText() {
      return this.$store.state.currentLightDark ? "drop-shadow-xs" : "drop-light-xs";
    },
  },
};
</script>

<style scoped>
.drop-shadow-xs {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 1));
}

.drop-light-xs {
  filter: drop-shadow(1px 1px 1px rgb(151, 151, 151));
}
</style>
