<template>
  <div class="fixed hidden w-0 h-0 pointer-events-none"></div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    this.getNowPlaying();
  },
  methods: {
    async getAccessToken() {
      const TOKEN_ENDPOINT = "https://accounts.spotify.com/api/token";
      const client_id = process.env.VUE_APP_CLIENT_ID;
      const client_secret = process.env.VUE_APP_CLIENT_SECRET;
      const refresh_token = process.env.VUE_APP_REFRESH_TOKEN;

      const basic = btoa(`${client_id}:${client_secret}`);
      try {
        const response = await axios.post(
          TOKEN_ENDPOINT,
          new URLSearchParams({
            grant_type: "refresh_token",
            refresh_token,
          }),
          {
            headers: {
              Authorization: `Basic ${basic}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        return response.data;
      } catch (error) {
        return null;
      }
    },
    ...mapMutations(["setSongInfo"]),
    async getNowPlaying() {
      const NOW_PLAYING_ENDPOINT =
        "https://api.spotify.com/v1/me/player/currently-playing";

      try {
        const { access_token } = await this.getAccessToken();

        const response = await axios.get(NOW_PLAYING_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        if (response.status > 400) {
          throw new Error("No se puede obtener la canción");
        } else if (response.status > 204) {
          throw new Error("No está activo");
        }

        this.data = response.data;

        const songInfo = {
          progress: response.data.progress_ms,
          duration: response.data.item.duration_ms,
          songName: response.data.item.name,
          artists: response.data.item.artists.map((artist) => ({
            name: artist.name,
            link: artist.external_urls.spotify,
          })),
          songLink: response.data.item.external_urls.spotify,
          image: response.data.item.album.images[0].url,
          startTime: Date.now()
        };
        const timeout = songInfo.duration - songInfo.progress + 1000;

        this.setSongInfo(songInfo);
        setTimeout(() => {
          this.getNowPlaying();
        }, timeout);
      } catch (error) {
        this.data = null;
        this.setSongInfo(this.data);
        setTimeout(() => {
          this.getNowPlaying();
        }, 180000);
      }
    },
  },
};
</script>
