import { createApp } from 'vue'
import App from './App/Show.vue'
import router from './router';
import store from './store';
import VueScrollTo from 'vue-scrollto'
import i18n from './i18n.js'
import './index.css'

createApp(App).use(router).use(store).use(i18n).use(VueScrollTo, {
    easing: 'ease',
    duration: 300,
    offset: 1,
    force: true,
    cancelable: false,
    onStart: () => {
        store.commit('setScrollInProgress', true);
      },
    onDone: () => {
        store.commit('setScrollInProgress', false);
      },
    onCancel: false,
    x: false,
    y: true
}).mount('#app')
