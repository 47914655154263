<template>
    <div class="p-0.5 relative card w-full h-full" :style="{
        '--x': `${$store.state.cursor.pageX - (el?.offsetLeft ?? 0)}px`,
        '--y': `${$store.state.cursor.pageY - (el?.offsetTop ?? 0)}px`
    }" ref="el">
        <div
            class="bg-[#fafafa] dark:bg-[#121113] relative rounded-xl flex flex-col w-full h-full">
            <slot>

            </slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            el: null,
        }
    },
    mounted() {
        this.el = this.$refs.el instanceof HTMLElement ? this.$refs.el : null;
    },
}
</script> 

<style scoped>
.card::before {
    content: '';
    position: absolute;
    border-radius: 0.75rem;
    inset: 0;
    background: radial-gradient(200px circle at var(--x) var(--y), #22d3ee, transparent);
}
</style>
