<template>
  <h1 class="text-4xl md:text-5xl font-bold">Sebastián Ríos</h1>
  <h2 class="text-xl font-bold opacity-90">
    {{ $t("computer_engineer") }}
  </h2>
  <div class="font-bold text-sm md:text-base text-primary-500/90 dark:text-neutral-300/90">
    {{ $t("basic_info_title") }}
    <span
      class="text-cyan-400 inline-flex flex-col h-[calc(theme(fontSize.sm)*theme(lineHeight.relaxed))] md:h-[calc(theme(fontSize.base)*theme(lineHeight.relaxed))] overflow-hidden">
      <ul class="block animate-text-slide text-left leading-tight [&_li]:block space-y-2">
        <li>{{ $t("basic_info_actions_1") }}</li>
        <li>{{ $t("basic_info_actions_2") }}</li>
        <li>{{ $t("basic_info_actions_3") }}</li>
        <li>{{ $t("basic_info_actions_4") }}</li>
        <li>{{ $t("basic_info_actions_5") }}</li>
        <li>{{ $t("basic_info_actions_6") }}</li>
        <li>{{ $t("basic_info_actions_7") }}</li>
        <li>{{ $t("basic_info_actions_8") }}</li>
        <li aria-hidden="true">{{ $t("basic_info_actions_1") }}</li>
      </ul>
    </span>
  </div>
</template>
