import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    title_in: "Portfolio — Seb",
    title_out: "See you later!",
    description: "Hi, I'm Seb, Chilean, Full-Stack web developer, focused on creating web and mobile applications.",
    computer_engineer: "Computer Engineer",
    focused_on_web_full_stack: "Focused on Web Development, Full-Stack",
    basic_info_title: "Dedicated to",
    basic_info_actions_1: "Develop",
    basic_info_actions_2: "Innovate",
    basic_info_actions_3: "Solve",
    basic_info_actions_4: "Collaborate",
    basic_info_actions_5: "Create",
    basic_info_actions_6: "Optimize",
    basic_info_actions_7: "Implement",
    basic_info_actions_8: "Iterate",
    about_me_section_title: "About me",
    my_beginnings_paragraph_1: "From a young age, I was fascinated by exploring how systems worked and finding ways to improve them. During my childhood, I focused on hexadecimal data modification and scripting, which allowed me to develop a deep understanding of how systems interact at a fundamental level.",
    my_beginnings_paragraph_2: "This early experience marked my introduction to the exciting realm of programming. As I gained more knowledge, my enthusiasm has only grown for everything I continue to uncover in this field.",
    my_beginnings_paragraph_3: "I have led and contributed to various projects, honing my skills and staying at the forefront of technological trends. My focus is always on delivering high-quality results that meet both operational and business needs.",
    exp_section_title: "Work experience",
    exp_1_month: "DEC",
    exp_1_rol: "Software Engineer",
    exp_1_workposition: "Junior Developer",
    exp_1_description: "Led the design and development of custom applications, using StarUML to create diagrams that met the specific requirements of the Ultraport Risk prevention department (HSE).\n\nManaged MySQL databases, implemented secure web services with authentication, and encrypted data in transit.\n\nIn the realm of mobile applications, utilized Android Studio to create comprehensive solutions, from integrating web services to authentication systems and QR scanners.\n\nHighlighted the creation of a QR code management system that not only generated and managed codes but also allowed visualization of graphics based on the scans.\n\nAdditionally, took on the responsibility of managing Virtual Private Servers (VPS), addressing tasks such as service activation, configuration, and security, using FTP/SFTP, DNS configuration, and deploying web services in a Linux environment (Ubuntu 20.04).\n\nMaintained an efficient workflow with GIT as a version control system, facilitating tracking of code changes.",
    project_section_title: "Projects",
    project_1_description: "Counter-Strike 1.6 community with 6 servers and custom game modes, developed plugins, adding up to 168 slots in total.",
    project_2_description: "QR system to access public/private information with intranet, PPE inventory, graphics, historical data, and traceability.",
    project_3_description: "Shows real-time data from streamer Ricoy through the official Twitch API.",
    project_4_description: "LandingPage of LexManager: app to efficiently manage the work of lawyers.",
    project_5_description: "The classic Snake with local TOP 5 score, dynamic color, adjustable joystick, and nostalgic retro style.",
    project_6_description: "Collects user data from several hotels in Habbo using the official API, interface faithful to Habbo Classic.",
    project_7_description: "BattleBit server list with search, filtering, and sorting, using the official API.",
    project_8_description: "Shows recent earthquakes in Chile on a map with detailed information, performing Web Scraping to CSN.",
    project_9_description: "Interactive laboratory to visualize and adjust mathematical operations, patterns, and figures.",
    project_10_description: "Python script that displays the annual contributions in GitHub by generating a customizable SVG with animation.",
    project_11_description: "Captures and visualizes audio frequency waves in two graphical representations. Complete audio spectrum and real-time during audio playback.",
    project_12_description: "Real-time earthquake data in Chile for developers and enthusiasts. It has its complete documentation.",
    technologies_section_title: "Technologies used",
    dyn_home_option: "Home",
    dyn_exp_option: "Experience",
    dyn_project_option: "Projects",
    dyn_more_option: "More",
    user_disconnected: "Seb disconnected from Spotify",
  },
  es: {
    title_in: "Portafolio — Seb",
    title_out: "¡Hasta luego!",
    description: "Hola, soy Seb, chileno, desarrollador web Full-Stack, enfocado en crear aplicaciones web y móviles.",
    computer_engineer: "Ingeniero en Informática",
    focused_on_web_full_stack: "Centrado en el desarrollo web, Full-Stack",
    basic_info_title: "Dedicado a",
    basic_info_actions_1: "Desarrollar",
    basic_info_actions_2: "Innovar",
    basic_info_actions_3: "Solucionar",
    basic_info_actions_4: "Colaborar",
    basic_info_actions_5: "Crear",
    basic_info_actions_6: "Optimizar",
    basic_info_actions_7: "Implementar",
    basic_info_actions_8: "Iterar",
    about_me_section_title: "Acerca de mí",
    my_beginnings_paragraph_1: "Desde joven, me fascinó explorar cómo funcionaban los sistemas y buscar maneras de mejorarlos. Durante mi niñez, me dediqué a la modificación de datos hexadecimales y al scripting, lo que me permitió desarrollar una sólida comprensión de cómo interactúan los sistemas a nivel fundamental.",
    my_beginnings_paragraph_2: "Esta experiencia temprana marcó mi introducción al fascinante mundo de la programación. A medida que he adquirido más conocimientos, mi entusiasmo por todo lo que sigo descubriendo en este campo no ha hecho más que crecer.",
    my_beginnings_paragraph_3: "He liderado y contribuido a diversos proyectos, perfeccionando mis habilidades y manteniéndome a la vanguardia de las tendencias tecnológicas. Mi enfoque está siempre en entregar resultados de alta calidad que satisfagan tanto las necesidades operacionales como las del negocio.",
    exp_section_title: "Experiencia laboral",
    exp_1_month: "DIC",
    exp_1_rol: "Ingeniero de Software",
    exp_1_workposition: "Desarrollador Junior",
    exp_1_description: "Lideré el diseño y desarrollo de aplicaciones personalizadas, utilizando StarUML para crear diagramas que cumplían con los requisitos específicos del departamento de prevención de riesgos Ultraport (HSE).\n\nGestioné bases de datos MySQL, implementé servicios web seguros con autenticación y cifrado en tránsito.\n\nEn el ámbito de las aplicaciones móviles, utilicé Android Studio para crear soluciones integrales, desde la integración de servicios web hasta sistemas de autenticación y escáneres QR.\n\nDestaco la creación de un sistema gestor de códigos QR que no solo generaba y gestionaba códigos, sino que también permitía visualizar gráficos basados en los escaneos.\n\nAdicionalmente, asumí la responsabilidad de la gestión de servidores privados virtuales (VPS), abordando tareas como la activación de servicios, configuración y seguridad, utilizando FTP/SFTP, configuración DNS y deployment de servicios web en un entorno Linux (Ubuntu 20.04).\n\nMantuve un flujo de trabajo eficiente con GIT como controlador de versiones, facilitando el seguimiento de los cambios en el código.",
    project_section_title: "Proyectos",
    project_1_description: "Comunidad de Counter-Strike 1.6 con 6 servidores y modos de juego personalizados, plugins desarrollados, sumando 168 slots en total.",
    project_2_description: "Sistema QR para acceder información pública/privada con intranet, inventario EPP, gráficos, datos históricos y trazabilidad.",
    project_3_description: "Muestra los datos en tiempo real del streamer Ricoy mediante la API oficial de Twitch.",
    project_4_description: "LandingPage de LexManager: app para gestionar eficientemente el trabajo de abogados.",
    project_5_description: "El clásico Snake con puntuación local TOP 5, color dinámico, joystick ajustable y estilo retro nostálgico.",
    project_6_description: "Recopila datos de usuarios de varios hoteles en Habbo utilizando la API oficial, interfaz fiel a Habbo Classic.",
    project_7_description: "Listado de servidores de BattleBit con búsqueda, filtrado y ordenación, utilizando la API oficial.",
    project_8_description: "Muestra sismos recientes en Chile en un mapa con información detallada, realizando Web Scraping al CSN.",
    project_9_description: "Laboratorio interactivo para visualizar y ajustar operaciones matemáticas, patrones y figuras.",
    project_10_description: "Script en Python que muestra las contribuciones anuales en GitHub generando un SVG personalizable con animación.",
    project_11_description: "Captura y visualiza ondas de frecuencia de audio en dos representaciones gráficas. Espectro de audio completo y en tiempo real durante la reproducción de audio.",
    project_12_description: "Datos de sismos en Chile en tiempo real para desarrolladores y entusiastas. Dispone con su documentación completa.",
    technologies_section_title: "Tecnologías utilizadas",
    dyn_home_option: "Inicio",
    dyn_exp_option: "Experiencia",
    dyn_project_option: "Proyectos",
    dyn_more_option: "Más",
    user_disconnected: "Seb se desconectó de Spotify",
  }
}

const storedLanguage = localStorage.getItem('language') || 'es';

const i18n = createI18n({
  locale: storedLanguage,
  messages
})

export default i18n