<template>
  <div class="fixed pointer-events-none bottom-0 z-[200] w-full h-full">
    <div class="relative w-full h-full flex items-end inset-x-0 pb-4 mx-full px-6" ref="menuContainer">
      <div
        class="relative pointer-events-auto h-[50px] mx-auto px-2 flex items-center overflow-y-hidden rounded-[14px] border border-neutral-300 bg-neutral-100 dark:border-primary-500 dark:bg-primary-800"
        ref="navbarContainer">
        <span class="absolute bottom-0 top-0 z-10 flex overflow-hidden rounded-[6px] py-2 transition-all duration-300"
          :style="{
            left: `${indicatorLeft}px`,
            width: `${indicatorWidth}px`,
            opacity: `${indicatorOpacity}`,
          }">
          <span class="h-full w-full rounded-[6px] bg-neutral-200 dark:bg-primary-500"></span>
        </span>
        <button v-for="route in routeLinks" :key="route.id" @click="toggleGOTOSection(route.section)"
          class="z-10 w-max px-3 text-neutral-500 dark:text-stone-200 cursor-none" :ref="`routerLinkRef${route.id}`">
          <AnimationToggleCursor class="w-max">
            <p class="hidden md:block" v-if="route.section == 'home'">
              {{ $t("dyn_home_option") }}
            </p>
            <p class="hidden md:block" v-if="route.section == 'experience'">
              {{ $t("dyn_exp_option") }}
            </p>
            <p class="hidden md:block" v-if="route.section == 'projects'">
              {{ $t("dyn_project_option") }}
            </p>
            <Home v-if="route.icon == 'Home'" class="w-4 h-4 md:hidden" />
            <Book v-if="route.icon == 'Book'" class="w-4 h-4 md:hidden" />
            <Suitcase v-if="route.icon == 'Suitcase'" class="w-4 h-4 md:hidden" />
          </AnimationToggleCursor>
        </button>
        <button class="w-max px-3 border-l border-neutral-300 dark:border-primary-500 cursor-none" :class="isMenuActive
          ? 'text-black dark:text-white'
          : 'text-neutral-500 md:hover:text-black dark:text-stone-200 dark:md:hover:text-white'
          " @click="setterMenuActive(!isMenuActive)">
          <AnimationToggleCursor class="w-max">
            <p class="hidden md:block">{{ $t("dyn_more_option") }}</p>
            <Menu class="w-4 h-4 md:hidden" />
          </AnimationToggleCursor>
        </button>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="isMenuActive" class="absolute flex flex-col items-end space-y-3 pb-3 pointer-events-auto" :style="{
          right: `${floatMenuPosition.x}px`,
        }">
          <AnimationToggleCursor v-if="counter >= 2">
            <button ref="songModalButton" :class="{ 'invisible pointer-events-none': songInfo === null }"
              @click="setterSongModal"
              class="w-7 h-7 p-[7px] bg-neutral-100 dark:bg-primary-800 border border-neutral-300 dark:border-primary-500 text-neutral-700 dark:text-neutral-300 rounded-full cursor-none animate-expand-fade-in">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="animate-spin-slow">
                <path fill="currentColor" fill-rule="evenodd"
                  d="M20 0v16a3.996 3.996 0 1 1-7.992 0c0-2.209 1.787-4 3.998-4 .729 0 .992.195 1.993.535V2H7.992v14A3.996 3.996 0 1 1 0 16c0-2.209 1.787-4 3.999-4 .728 0 .991.195 1.992.535V0H20Z" />
              </svg>
            </button>
          </AnimationToggleCursor>
          <div v-else class="invisible w-7 h-7"></div>
          <AnimationToggleCursor v-if="counter >= 1">
            <button ref="langModalButton" @click="toggleLang()"
              class="w-7 h-7 p-[7px] bg-neutral-100 dark:bg-primary-800 border border-neutral-300 dark:border-primary-500 text-neutral-700 dark:text-neutral-300 rounded-full cursor-none animate-expand-fade-in">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                <path
                  d="m478.33 433.6-90-218a22 22 0 0 0-40.67 0l-90 218a22 22 0 1 0 40.67 16.79L316.66 406h102.67l18.33 44.39A22 22 0 0 0 458 464a22 22 0 0 0 20.32-30.4ZM334.83 362 368 281.65 401.17 362ZM267.84 342.92a22 22 0 0 0-4.89-30.7c-.2-.15-15-11.13-36.49-34.73 39.65-53.68 62.11-114.75 71.27-143.49H330a22 22 0 0 0 0-44H214V70a22 22 0 0 0-44 0v20H54a22 22 0 0 0 0 44h197.25c-9.52 26.95-27.05 69.5-53.79 108.36-31.41-41.68-43.08-68.65-43.17-68.87a22 22 0 0 0-40.58 17c.58 1.38 14.55 34.23 52.86 83.93.92 1.19 1.83 2.35 2.74 3.51-39.24 44.35-77.74 71.86-93.85 80.74a22 22 0 1 0 21.07 38.63c2.16-1.18 48.6-26.89 101.63-85.59 22.52 24.08 38 35.44 38.93 36.1a22 22 0 0 0 30.75-4.9Z" />
              </svg>
            </button>
          </AnimationToggleCursor>
          <div v-else class="invisible w-7 h-7"></div>
          <AnimationToggleCursor v-if="counter >= 0">
            <button ref="lightModalButton" @click="toggleLightDark()"
              class="w-7 h-7 p-[7px] bg-neutral-100 dark:bg-primary-800 border border-neutral-300 dark:border-primary-500 text-neutral-700 dark:text-neutral-300 rounded-full cursor-none animate-expand-fade-in">
              <svg v-if="currentLightDark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <path fill="currentColor" d="M17 12a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z" />
                <path fill="currentColor" fill-rule="evenodd"
                  d="M12 1.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75ZM3.669 3.716a.75.75 0 0 1 1.06-.047L6.95 5.7a.75.75 0 1 1-1.012 1.107L3.716 4.776a.75.75 0 0 1-.047-1.06Zm16.662 0a.75.75 0 0 1-.047 1.06l-2.222 2.031A.75.75 0 0 1 17.05 5.7l2.222-2.031a.75.75 0 0 1 1.06.047ZM1.25 12a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75Zm18 0a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75Zm-2.224 5.025a.75.75 0 0 1 1.06 0l2.222 2.223a.75.75 0 0 1-1.06 1.06l-2.223-2.222a.75.75 0 0 1 0-1.06Zm-10.051 0a.75.75 0 0 1 0 1.061l-2.223 2.222a.75.75 0 0 1-1.06-1.06l2.222-2.223a.75.75 0 0 1 1.06 0ZM12 19.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75Z"
                  clip-rule="evenodd" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M12 22c5.523 0 10-4.477 10-10 0-.463-.694-.54-.933-.143a6.5 6.5 0 1 1-8.924-8.924C12.54 2.693 12.463 2 12 2 6.477 2 2 6.477 2 12s4.477 10 10 10Z" />
              </svg>
            </button>
          </AnimationToggleCursor>
          <div v-else class="invisible w-7 h-7"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AnimationToggleCursor from "@/components/AnimationToggleCursor.vue";
import Book from "@/assets/icons/Book.vue";
import Home from "@/assets/icons/Home.vue";
import Suitcase from "@/assets/icons/Suitcase.vue";
import Menu from "@/assets/icons/Menu.vue";

export default {
  components: {
    AnimationToggleCursor,
    Book,
    Home,
    Suitcase,
    Menu,
  },
  data() {
    return {
      selectedLinkId: null,
      isDarkMode: false,
      indicatorLeft: 0,
      indicatorWidth: 0,
      indicatorOpacity: 0,
      floatMenuPosition: { x: null, y: null },
      routeLinks: [
        {
          id: 1,
          icon: "Home",
          section: "home",
        },
        {
          id: 2,
          icon: "Book",
          section: "experience",
        },
        {
          id: 3,
          icon: "Suitcase",
          section: "projects",
        },
      ],
      tempGOTOSection: "home",
      counter: 0,
    };
  },
  computed: {
    ...mapState(["currentSection"]),
    ...mapState(["scrollInProgress"]),
    ...mapState(["songInfo"]),
    ...mapState(["isMenuActive"]),
    ...mapState(["currentLightDark"]),
    ...mapState(["currentLang"]),
  },
  methods: {
    selectLink(routeId) {
      this.selectedLinkId = routeId;
      this.updateIndicatorPositions();
    },
    updateIndicatorPositions() {
      const containerRect = this.$refs.navbarContainer.getBoundingClientRect();
      const selectedLink = this.routeLinks.find((r) => r.section === this.currentSection);
      this.floatMenuPosition.x = containerRect.left - 40;
      this.floatMenuPosition.y = containerRect.top - 70;

      if (selectedLink) {
        const el = this.$refs[`routerLinkRef${selectedLink.id}`][0];
        const rect = el.getBoundingClientRect();

        this.indicatorLeft = rect.left - containerRect.left;
        this.indicatorWidth = rect.width;
      }
    },
    closeOnOutsideClick(event) {
      if (!this.isMenuActive) return;
      
      if (
        (this.$refs.menuContainer && !this.$refs.menuContainer.contains(event.target))
      ) {
        this.setterMenuActive(false);

      }
    },
    setterCurrentSection() {
      const currentSection = this.routeLinks.find(
        (r) => this.currentSection === r.section
      );
      this.selectedLinkId = currentSection ? currentSection.id : null;
      if (this.selectedLinkId) this.selectLink(this.selectedLinkId);
    },
    toggleGOTOSection(state) {
      if (this.scrollInProgress) return;
      this.$scrollTo(state == "home" ? window : `#${state}`, 500, {
        easing: "ease-in-out",
      });
    },
    ...mapMutations(["setCurrentSongModal"]),
    setterSongModal() {
      this.setCurrentSongModal(true);
    },
    ...mapMutations(["setMenuActive"]),
    setterMenuActive(state) {
      this.setMenuActive(state);
    },
    ...mapMutations(["setCurrentLightDark"]),
    toggleLightDark() {
      this.setCurrentLightDark(!this.currentLightDark);
    },
    ...mapMutations(["setCurrentLang"]),
    toggleLang() {
      var lang = !this.currentLang;
      this.setCurrentLang(lang);
      this.$i18n.locale = lang ? "en" : "es";
    },
  },
  mounted() {
    setTimeout(() => {
      this.setterCurrentSection();
    }, 100);
    setTimeout(() => {
      this.indicatorOpacity = 100;
    }, 300);
    this.updateIndicatorPositions();
    window.addEventListener("resize", this.updateIndicatorPositions);
    window.addEventListener('click', this.closeOnOutsideClick);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateIndicatorPositions);
    window.removeEventListener('click', this.closeOnOutsideClick);
  },
  watch: {
    currentSection() {
      this.setterCurrentSection();
    },
    isMenuActive(newVal) {
      if (!newVal) return;
      this.counter = null;
      setTimeout(() => {
        this.counter = 1;
        setTimeout(() => {
          this.counter = 2;
        }, 200);
      }, 200);
    },
    "$i18n.locale": {
      handler() {
        setTimeout(() => {
          this.updateIndicatorPositions();
        }, 100);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
