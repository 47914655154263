<template>
  <h2
    class="text-left md:text-center font-bold md:text-4xl text-xl text-black dark:text-white mb-0 md:mb-14 py-4 md:py-4 top-0 sticky md:static bg-neutral-50 dark:bg-primary-900 z-[50]">
    {{ $t("project_section_title") }}
  </h2>
  <div class="flex flex-col justify-center md:gap-y-20 gap-y-12 paragraph">
    <div v-for="(section, id) in sections" :key="id">
      <h1 class="text-left md:text-center mb-4 md:text-lg font-bold text-neutral-600 dark:text-neutral-500">
        {{ section.year }}
      </h1>
      <div class="flex justify-normal md:justify-center">
        <div class="grid grid-cols-1 gap-4" :class="section.projects.length > 1 ? 'md:grid-cols-2' : ' md:w-1/2'">
          <div v-for="(project, id) in section.projects"
            @click="project.isWebEnabled === true ? openLink(project.url) : null" :key="id"
            class="w-full min-h-20 max-h-max">
            <Card>
              <component :is="getComponent(project)"
                class="w-full h-full p-1.5 group rounded-lg hover:bg-neutral-200/30 dark:hover:bg-primary-800 duration-700">
                <div
                  class="flex items-center text-neutral-500 group-hover:text-black dark:text-neutral-300 dark:group-hover:text-white duration-700">
                  <h1 class="font-semibold">
                    {{ project.name }}
                  </h1>
                  <svg v-if="project.isWebEnabled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor"
                    class="inline-block h-4 w-4 shrink-0 duration-700 transition-all group-hover:scale-125 group-hover:-translate-y-[2px] group-hover:translate-x-[2px] ml-1"
                    aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z"
                      clip-rule="evenodd"></path>
                  </svg>
                </div>
                <p
                  class="text-sm text-black/60 group-hover:text-black/80 dark:text-white/60 dark:group-hover:text-white/80 duration-700">
                  {{ project.description }}
                </p>
              </component>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProjects } from "@/utils/projects";
import AnimationToggleCursor from "@/components/AnimationToggleCursor.vue";
import Card from "@/components/Card.vue";

export default {
  data() {
    return {
      sections: [],
    };
  },
  components: {
    AnimationToggleCursor,
    Card
  },
  methods: {
    updateDescription() {
      this.sections.forEach((section) => {
        section.projects.forEach((project) => {
          project.description = this.$t(project.title_description);
        });
      });
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    getComponent(project) {
      return project.isWebEnabled ? 'AnimationToggleCursor' : 'div';
    },
  },
  mounted() {
    this.sections = getProjects();
    this.updateDescription();
  },
  watch: {
    "$i18n.locale": {
      handler() {
        this.updateDescription();
      },
      deep: true,
    },
  },
};
</script>
