<template>
  <div class="relative px-[2px]">
    <div
      class="pointer-events-none absolute left-0 z-30 w-12 h-full to-transparent backdrop-blur-xl [-webkit-mask-image:linear-gradient(to_right,black,transparent)] bg-neutral-50 dark:bg-primary-900">
    </div>
    <div
      class="pointer-events-none absolute right-0 z-30 w-12 h-full to-transparent backdrop-blur-xl [-webkit-mask-image:linear-gradient(to_left,black,transparent)] bg-neutral-50 dark:bg-primary-900">
    </div>
    <VueMarqueeSlider id="marquee-slider" :speed="20000" :space="50" :width="80">
      <img :src="require('@/assets/images/pawn.webp')"
        class="filter opacity-60 dark:opacity-50 brightness grayscale scale-[30%]" width="100" height="100" alt="Pawn" />
      <img :src="require('@/assets/images/laravel.webp')"
        class="filter opacity-80 dark:opacity-60 brightness grayscale dark:invert scale-[60%]" width="100" height="100"
        alt="Laravel" />
      <img :src="require('@/assets/images/vue.webp')"
        class="filter opacity-80 dark:opacity-60 brightness grayscale scale-90" width="100" height="100" alt="Vue" />
      <img :src="require('@/assets/images/android-studio.webp')"
        class="filter dark:opacity-40 brightness grayscale scale-[60%]" width="100" height="100" alt="Android Studio" />
      <img :src="require('@/assets/images/tailwindcss.webp')" class="filter dark:opacity-60 brightness grayscale scale-75"
        width="100" height="100" alt="Tailwind CSS" />
      <img :src="require('@/assets/images/php.webp')"
        class="filter opacity-40 dark:opacity-30 brightness grayscale dark:invert scale-75" width="100" height="100"
        alt="PHP" />
      <img :src="require('@/assets/images/python.webp')"
        class="filter opacity-80 dark:opacity-60 brightness grayscale dark:invert scale-[70%]" width="100" height="100"
        alt="Python" />
      <img :src="require('@/assets/images/git.webp')"
        class="filter opacity-40 dark:opacity-30 brightness grayscale dark:invert scale-[70%]" width="100" height="100"
        alt="Git" />
    </VueMarqueeSlider>
  </div>
</template>

<script>
import { VueMarqueeSlider } from "vue3-marquee-slider";

export default {
  components: {
    VueMarqueeSlider,
  },
};
</script>

<style>
._marqueeSlider_1htck_3 {
  overflow: hidden;
}

._marqueeSliderContainer_1htck_11 {
  width: 100%;
  height: 100px;
  animation-name: _horizontalAnimation_1htck_1;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: flex;
  align-items: center;
}

@keyframes _horizontalAnimation_1htck_1 {
  0% {
    transform: translate(0);
  }

  to {
    transform: translate(-100%);
  }
}
</style>
