<template>
  <div class="flex flex-col">
    <ReproductorTheme />
    <div class="flex md:flex-row flex-col">
      <div class="w-full md:w-1/2 md:sticky top-[80px] h-max lg:h-[80vh] flex text-black dark:text-white">
        <div class="h-full flex-col justify-between relative">
          <BasicInfo />
          <SocialMedia />
        </div>
      </div>
      <div class="w-full md:w-1/2 flex flex-col gap-y-10">
        <section id="home" :aria-label="$t('about_me_section_title')" class="md:px-3 mt-10 md:mt-0">
          <AboutMe />
        </section>
        <section id="experience" :aria-label="$t('exp_section_title')">
          <MyExperience />
        </section>
      </div>
    </div>
    <div>
      <TecnologiesMarquee />
      <section id="projects" :aria-label="$t('project_section_title')">
        <MyProjects />
      </section>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import AboutMe from "./Partials/AboutMe.vue";
import SocialMedia from "./Partials/SocialMedia.vue";
import ReproductorTheme from "@/components/ReproductorTheme.vue";
import MyExperience from "./Partials/MyExperience.vue";
import TecnologiesMarquee from "./Partials/TecnologiesMarquee.vue";
import MyProjects from "./Partials/MyProjects.vue";
import BasicInfo from "./Partials/BasicInfo.vue";

export default {
  components: {
    AboutMe,
    SocialMedia,
    ReproductorTheme,
    MyExperience,
    TecnologiesMarquee,
    MyProjects,
    BasicInfo,
  },
  data() {
    return {
      sections: [
        {
          id: "home",
        },
        {
          id: "experience",
        },
        {
          id: "projects",
        },
      ],
      currentSection: null,
      scrollThreshold: 0,
      sectionOffsets: {},
    };
  },
  mounted() {
    this.calculateSectionOffsets();
    this.handleScroll();

    this.modileDetector();

    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.calculateSectionOffsets);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.calculateSectionOffsets);
  },
  methods: {
    calculateSectionOffsets() {
      this.sections.forEach((section) => {
        const el = document.getElementById(section.id);
        const sectionTop = el.offsetTop;
        this.sectionOffsets[section.id] = {
          top: sectionTop - this.scrollThreshold,
          bottom: sectionTop + el.offsetHeight + this.scrollThreshold,
        };
      });

      this.modileDetector();
    },
    handleScroll() {
      const scrollY = window.scrollY;

      for (let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i];
        const offsets = this.sectionOffsets[section.id];

        if (scrollY >= offsets.top && scrollY <= offsets.bottom) {
          if (this.currentSection !== section.id) {
            this.currentSection = section.id;
            this.toggleCurrentSection(this.currentSection);
          }
          break;
        }
      }
    },
    ...mapMutations(["setCurrentSection"]),
    toggleCurrentSection(state) {
      this.setCurrentSection(state);
    },
    modileDetector() {
      this.$store.state.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
  watch: {
    "$i18n.locale": {
      handler() {
        setTimeout(() => {
          this.calculateSectionOffsets();
        }, 200);
      },
      deep: true,
    },
  },
};
</script>
